// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';

// const TickList = ({ items }) => {
//   return (
//     <ul className="tick-list">
//       {items.map((item, index) => (
//         <li key={index} className='my-3'>
//           <FontAwesomeIcon icon={faCheck} className="tick-icon mx-7 " />
//           {item}
//         </li>
//       ))}
//     </ul>
//   );
// };
// export default TickList;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const TickList = ({ items, isExcluded }) => {
  // Check if items is defined
  if (!items) {
    return null; // or return a default message or an empty list as needed
  }

  const icon = isExcluded ? faTimes : faCheck;

  return (
    <ul className="tick-list">
      {items.map((item, index) => (
        <li key={index} className='my-3'>
          <FontAwesomeIcon icon={icon} className="tick-icon mx-7 " />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default TickList;

