import "./SundarjagReview.css"

const SundarjagReview = ({userName, description, rating  , key})=>{
    return(
        <div className="review-card" key={key} >
            
            <img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJQAnQMBIgACEQEDEQH/xAAbAAEBAAMBAQEAAAAAAAAAAAAABAECAwUGB//EADIQAQACAQMBBgQEBgMAAAAAAAABAgMEESExBRJBUWFxMkJSgRQikaETQ1NicsEjMzT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AP3EAAAAAAAADeAA3AAAAAAAAAAAAAAccmXbivXzB1taK9ZcrZo+WP1cZmZ6zuwDpOa0+O3s179vqlqIN4yWj5pbRmt6OQCiuas8TxLpE7o21b2rPE/ZRWNMeSt444bgAAAAAAA55r92vHWQa5snyw4jCABMxHUARZ9dzNcMRx80pp1GW3M5LfadgesPKpqs1Z3i8z6W5W6fV1zTFbRFb/tIKBlgGd5id/FTiv3o56pW1bTWd46grGKzFoiYZUAAAAEuW3etKjJO1JlL4gwAgIu0M07xirPTm0/6WvI1E758k+sg5gKgfeQB6uky/wAXFEz8UcWdkHZk7ZL1843egisAA7YLfK7pKT3bxKtQAAAByzztT3lO76j4Y93BAAAeTq6dzUX9Z3esn1mCctYtX46+HnAPMCeOPEVAG2Olst4pSN5BX2ZXnJf7L2mHHGLHFI8PHzbIoADKuvwwkVU+GPZRsAAADnmjeiZXeN6zCWeqDAAAxe9aV7152j1SZNfWOMdZn1kHbNpseWZmY7tvOE1tBb5b1n3hj8ffwpU/H5Poqo2poOfz34/thZixUxV2pWI8/ND+PyfRUjX3+ioPQE2LW4rzEW3pPr0/VSgAAyrjiITYo7148lSgAAAAmzV7tt/NS1yV79dgSMXvGOs2t0jq2mJjffqg7Sv8OOOnWQTZ81s1pm08eEb9HMBAAAABVo9TNLRjvO9J6eiUB7Y46S/8TBWZ6xwpx071vbqK64K7R3vN1YjiOGQAAAAAAc8uPvcx1eH2h/6pjxiIfQJ9VpMepj80bWjpaOoPnxRqdHm0/M171fqr0TiAAAAAREzO0RO67Tdm5Mm1s35KeXjIrp2VWb47xH1PUpWKxwxixUxUimOsRENwAAAAAAAAAANk2bQ6fLzOPafOvCkB5d+yY/l5Zj/KHOeycv8AUp+72AHjx2Tk8ctI9omXfH2Vjj/syWt7cPRAcsOmw4Y/48cR6+LqAAAAAAAAAAAAAAAAAAAAAAAAAAAP/9k=" className="profile-img"/>
            <span>{userName}</span>
            <p className="col-light r-description">{description}</p> 
            <span className="rating" >{rating}⭐</span>
        </div>
    )
}

export default SundarjagReview 