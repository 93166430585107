import "./Home.css";
import Navbar from "../../components/Navbar/Navbar";
import HomePoster from "../../components/HomePoster/HomePoster";
import PackageCard from "../../components/PackageCard/PackageCard";
import TravelingAddPoster from "../../components/TravelingAddPoster/TravelingAddPoster";
import Review from "../../components/Review/Review";
import Footer from "../../components/Footer/Footer";
// import WhyUs from "../../components/WhyUs/WhyUs";
import HomeImgGallery from "../../components/HomeImgGallery/HomeImgGallery";
import ImageCards from "../../components/ImageCards/ImageCards";
import ContactOnHome from "../../components/ContactOnHome/ContactOnHome";
const Home = () => {
  return (
    <div className="full-body">
      <Navbar />
      <HomePoster />
      <ImageCards />
      {/* <WhyUs /> */}
      <HomeImgGallery />
      <PackageCard />
      <div className="TravelingAddPoster">
        <TravelingAddPoster />
      </div>
      <ContactOnHome />
      <Review />
      <Footer />
    </div>
  );
};
export default Home;
