import React from "react";
import { Link } from "react-router-dom";
import "./PackageCard.css";
const HomePackageCard = () => {
  const packageData = [
    {
      id: 1,
      title: "Shimla and Manali",
      content: "Himalayan Beauty Beckons and Adventure Awaits.",
      imageUrl: "../shimlaManali.jpg", // image resolution 1920*2560
    },
    {
      id: 2,
      title: "Kashmir",
      content: "Paradise Found in Every Snowflake, Valley, and Shikara Ride.",
      imageUrl: "../kashmir_1.jpg",
    },
    {
      id: 4,
      title: "Madurai",
      content: "Tradition Meets Timeless Heritage.",
      imageUrl: "../madurai.jpg",
    },
    {
      id: 5,
      title: "Nepal",
      content: "Peaks Touch the Sky and Hearts Touch the Soul.",
      imageUrl: "../nepal.jpg",
    },
    // Add more cards as needed
  ];
  return (
    <>
      <div className="package-maindiv">
        <div className="package-containig-cards">
          {packageData.map((data, index) => (
            <div key={index} className="package-card">
              <img src={data?.imageUrl} alt={`Card ${data.id}`} />
              <h2 className="package-card-title">{data.title}</h2>
              <p className="package-card-content">{data.content}</p>
              <Link className="link" to={`/package/ ${data.id}`}>
                <button className="package-button">Details</button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HomePackageCard;
