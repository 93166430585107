import React, { useState } from "react";
import "./Service.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import serviceimg from "./service.jpg"

const Services = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const services = [
    {
      id: 1,
      name: "Customised Tours",
      imageUrl: "../service_tours.jpg",
      content:
        "Your dream destinations,your bucket list and your style along with your budget. With our Tailor-made trips, we'll put you in charge. Tailor-made tours offer you the flexibility to design your own trip exactly how you like it. Wherever locations you want to explore, we'll shortlist accommodation and experiences that as best suitable for you and your budget. We know how to piece together a trip you'll never forget.",
    },
    {
      id: 2,
      name: "Corporate Travel",
      imageUrl: "../service_4.jpg",
      content:
        "Looking for a fun filled outing with your colleagues? Well, we are curating the best of experiences for you to make travel memories with your workmates outside the confines of daily routine.This includes, providing expertise, organising holidays, conducting induction programmes and much more, thus offering a complete range of services related to all the corporate tourism needs.",
    },
    {
      id: 3,
      name: "Group Travel",
      imageUrl: "../temp.jpg",
      content:
        "Our goal is to design each and every trip/activity for the maximum satisfaction of our customer from the experience end as well as from the cost perspective. Join us as we make our humble efforts to make travel in India more responsible, affordable, and sustainable!We also undertake and organise private trek and camping for schools, classes, college, families and offices",
    },
    {
      id: 4,
      name: "Students and Youth Travel Programs",
      imageUrl: "../service_childs.jpg",
      content:
        "We curate customised and personalised trips for schools,collages,youth groups that include various options which fulfil the requirements.Creative Action Service Programs based Trips. Life Skill Exploration programs They get to learn the basic and essential character development skills through our Adventure Tours. Cultural Exploration Aims at helping the students explore the value a culture holds, the beauty of art and the importance of history.",
    },
    // Add more services as needed
  ];

  const handleServiceMouseOver = (serviceId) => {
    setHoveredService(serviceId);
  };

  const handleServiceMouseOut = () => {
    setHoveredService(null);
  };

  return (
    <>
      <Navbar />
      <div className="inside-this">
        <div className="main">
          <div className="top">
            <img src={serviceimg} alt="Top Image" />
          </div>
          <div className="serviceh1 ">
            <h1 className="headingservice">Sunderjag Holiday Services</h1>
          </div>
          <div className="imagediv">
            {services.map((service) => (
              <div
                key={service.id}
                className={`service-item ${
                  hoveredService === service.id ? "hovered" : ""
                }`}
                onMouseOver={() => handleServiceMouseOver(service.id)}
                onMouseOut={handleServiceMouseOut}
              >
                <div className="image-overlay">
                  <img src={service.imageUrl} alt={service.name} />
                  <div>
                    {hoveredService !== service.id && (
                      <div className="overlay-text">
                        <h3 className=" font-semibold">{service.name}</h3>
                      </div>
                    )}
                    {hoveredService === service.id && (
                      <div className="div-content">
                        <h3 className="overlay-content hover:transition-all">
                          {service.content}
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="why-us">
          <div className="why-choose-us">
            <h3 className="choose-h3">Why Choose Us</h3>
            <p className="choose-p">
              We are a travel marketplace curating Unique, Experiential &
              Sustainable holidays for you. Our trips are not limited to usual
              travel arrangements.
            </p>
            <div className="choose-li">
              <p>
                <i class="choose-span fa-solid fa-check"></i>A better value for
                money
              </p>
              <p>
                <i class="choose-span fa-solid fa-check"></i>Make travel more
                responsible, affordable, and sustainable!
              </p>
              <p>
                <i class="choose-span fa-solid fa-check"></i>Are experiential
                and believe in sustainable tourism
              </p>
            </div>
          </div>
          <div className="what-you-get">
            <h3 className="get-h3">What You Get</h3>
            <p className="get-p">
              We understand the needs of the modern traveller and so have custom
              plans that take your comfort and requirements into consideration
            </p>
            <div className="get-li">
              <p>
                <i class="choose-span fa-solid fa-check"></i>Wildlife, Adventure
                and Trekking Holidays.
              </p>
              <p>
                <i class="choose-span fa-solid fa-check"></i>Flexibility to
                design your own.
              </p>
              <p>
                <i class="choose-span fa-solid fa-check"></i>Tailor-made tours
                and packages.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
