// import React, { useState } from 'react';
// import './PackageInfo.css';

// const TourBooking = () => {
//   const [persons, setPersons] = useState({
//     below11: 0,
//     age12to18: 0,
//     above18: 0,
//   });

//   const prices = {
//     below11: 20000,   // Price per person below 11
//     age12to18: 30000,  // Price per person between 12-18
//     above18: 40000,    // Price per person above 18
//   };

//   const calculateTotalPrice = () => {
//     const { below11, age12to18, above18 } = persons;
//     return below11 * prices.below11 + age12to18 * prices.age12to18 + above18 * prices.above18;
//   };

//   const handleIncrement = (category) => {
//     setPersons((prevPersons) => ({
//       ...prevPersons,
//       [category]: prevPersons[category] + 1,
//     }));
//   };

//   const handleDecrement = (category) => {
//     setPersons((prevPersons) => ({
//       ...prevPersons,
//       [category]: Math.max(0, prevPersons[category] - 1),
//     }));
//   };

//   return (
//     <div className=''>
//       <div>
//         <h2 className=' mt-2'>Baby :  11</h2>
//         <button 
//         className='button-incr'
//         onClick={() => handleDecrement('below11')}>-</button>
//         <span>{persons.below11}</span>
//         <button 
//         className='button-incr'
//         onClick={() => handleIncrement('below11')}>+</button>
//       </div>
//       <hr className="border-t-2 border-gray-300" />
//       <div>
//         <h2> Children 
//                    12-18:</h2>
//         <button
//         className='button-incr'
//         onClick={() => handleDecrement('age12to18')}>-</button>
//         <span>{persons.age12to18}</span>
//         <button 
//         className='button-incr'
//         onClick={() => handleIncrement('age12to18')}>+</button>
//       </div>
      
//     <hr className="border-t-2 border-gray-300" />
         

//       <div>
//         <h2>Persons Above 18:</h2>
//         <button 
//         className='button-incr'
//         onClick={() => handleDecrement('above18')}>-</button>
//         <span>{persons.above18}</span>
        
//         <button 
//         className='button-incr'
//         onClick={() => handleIncrement('above18')}>+</button>
//       </div>
//       <hr className="border-t-2 border-gray-300" />


//       <div className='my-6'>
//         <h2 className='font-bold'>Total Price: ₹{calculateTotalPrice()}</h2>
//       </div>
//     </div>
//   );
// };

// export default TourBooking;


// TourBooking.js
// import React, { useState } from 'react';
// import './PackageInfo.css';

// const TourBooking = ({ city }) => {
//   const [persons, setPersons] = useState({
//     below11: 0,
//     age12to18: 0,
//     above18: 0,
//   });

//   const calculateTotalPrice = () => {
//     const {  age12to18 = 0, above18 = 0 } = persons;
//     const { prices } = city || {};

//     if (!prices) {
//       return 0;
//     }

//     return  age12to18 * prices.age12to18 + above18 * prices.above18;
//   };

//   const handleIncrement = (category) => {
//     setPersons((prevPersons) => ({
//       ...prevPersons,
//       [category]: prevPersons[category] + 1,
//     }));
//   };

//   const handleDecrement = (category) => {
//     setPersons((prevPersons) => ({
//       ...prevPersons,
//       [category]: Math.max(0, prevPersons[category] - 1),
//     }));
//   };

//   return (
//     <div className=''>
//       {/* <div>
//         <h2 className=' mt-2'>Baby :  11</h2>
//         <button
//           className='button-incr'
//           onClick={() => handleDecrement('below11')}
//         >
//           -
//         </button>
//         <span>{persons.below11}</span>
//         <button
//           className='button-incr'
//           onClick={() => handleIncrement('below11')}
//         >
//           +
//         </button>
//       </div> */}
//       <hr className="border-t-2 border-gray-300" />
//       <div>
//         <h2> Children 12-18:</h2>
//         <button
//           className='button-incr'
//           onClick={() => handleDecrement('age12to18')}
//         >
//           -
//         </button>
//         <span>{persons.age12to18}</span>
//         <button
//           className='button-incr'
//           onClick={() => handleIncrement('age12to18')}
//         >
//           +
//         </button>
//       </div>
//       <hr className="border-t-2 border-gray-300" />
//       <div>
//         <h2>Persons Above 18:</h2>
//         <button
//           className='button-incr'
//           onClick={() => handleDecrement('above18')}
//         >
//           -
//         </button>
//         <span>{persons.above18}</span>
//         <button
//           className='button-incr'
//           onClick={() => handleIncrement('above18')}
//         >
//           +
//         </button>
//       </div>
//       <hr className="border-t-2 border-gray-300" />
//       <div className='my-6'>
//         <h2 className='font-bold'>Total Price: ₹{calculateTotalPrice()}</h2>
//       </div>
//     </div>
//   );
// };

// export default TourBooking;
import React, { useState, useEffect } from 'react';
import './PackageInfo.css';

const TourBooking = ({ city }) => {
  const [persons, setPersons] = useState({
    below11: 0,
    age12to18: 0,
    above18: 0,
  });

  const [totalPrice, setTotalPrice] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateTotalPrice = () => {
    const { age12to18 = 0, above18 = 0 } = persons;
    const { prices } = city || {};

    if (!prices) {
      return 0;
    }

    return age12to18 * prices.age12to18 + above18 * prices.above18;
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [persons, city, calculateTotalPrice]);

  const handleIncrement = (category) => {
    setPersons((prevPersons) => ({
      ...prevPersons,
      [category]: prevPersons[category] + 1,
    }));
  };

  const handleDecrement = (category) => {
    setPersons((prevPersons) => ({
      ...prevPersons,
      [category]: Math.max(0, prevPersons[category] - 1),
    }));
  };

  return (
    <div className=''>
      <hr className="border-t-2 border-gray-300" />
      <div>
        <h2> Children 12-18:</h2>
        <button
          className='button-incr'
          onClick={() => handleDecrement('age12to18')}
        >
          -
        </button>
        <span>{persons.age12to18}</span>
        <button
          className='button-incr'
          onClick={() => handleIncrement('age12to18')}
        >
          +
        </button>
      </div>
      <hr className="border-t-2 border-gray-300" />
      <div>
        <h2>Persons Above 18:</h2>
        <button
          className='button-incr'
          onClick={() => handleDecrement('above18')}
        >
          -
        </button>
        <span>{persons.above18}</span>
        <button
          className='button-incr'
          onClick={() => handleIncrement('above18')}
        >
          +
        </button>
      </div>
      <hr className="border-t-2 border-gray-300" />
      <div className='my-6'>
        <h2 className='font-bold'>Total Price: ₹{totalPrice}</h2>
      </div>
    </div>
  );
};

export default TourBooking;




