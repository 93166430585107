import React, { useState, useEffect, useRef } from "react";
import "./Review.css";
// import Review from "./Review";
import axios from "axios"
import SundarjagReview from "../SundarjagReview/SundarjagReview";
import Model from 'react-modal';
import swal from "sweetalert";

const ReviewComponent = () => {
  const [userName, setUserName] = useState("");
  const [user, setUser] = useState([])
  const [reviews, setReviews] = useState([]);
  // const [newReview, setNewReview] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState(0)
  const [filter, setFilter] = useState("newest");
  const [showFilters, setShowFilters] = useState(false);
  const [expandedReviews, setExpandedReviews] = useState([]);
  const [isWritingReview, setIsWritingReview] = useState(false);
  const [visible, setVisible] = useState(false)

  const filterOptionsRef = useRef(null);

  const loadReviews = async () => {
    const response = await axios.get("/api/package/reviews")
    setReviews(response?.data?.data)
    console.log(description)
    console.log(rating)
    console.log(userName)

  }

  const submitReview = async () => {
    try {
      const userlocalStorage = JSON.parse(localStorage.getItem('user'));

      if (!userlocalStorage?.name) {

        // alert("Please Account login !");
        swal("Please Account login !", "🙏", "error")
        window.location.href = "/login";
      }
      const response = await axios.post("/api/sundarjag/reviews", {
        user: user?._id,
        userName: userName,
        packageName: "Sundarjag",
        description: description,
        rating: rating
      })

      if (response?.data?.success) {
        // alert("Review Added")
        swal("⭐", "Review Added !", "success")

        setVisible(false)
        window.location.reload();
      }

    } catch (err) {
      alert(err.message)
    }
  }

  const filteredReviews = () => {
    switch (filter) {
      case "newest":
        return reviews.filter(review => review.packageName === "Sundarjag").sort((a, b) => b.timestamp - a.timestamp);
      case "oldest":
        return reviews.filter(review => review.packageName === "Sundarjag").sort((a, b) => a.timestamp - b.timestamp);
      default:
        return reviews.filter(review => review.packageName === "Sundarjag");
    }
  };

  const handleRatingChange = (e) => {
    const inputValue = e.target.value;

    let parsedValue = parseFloat(inputValue);

    if (parsedValue > 5) {
      parsedValue = 5;
    }

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setRating(parsedValue);
    } else {
      setRating();
    }
  };

  useEffect(() => {
    const userStorage = JSON.parse(localStorage.getItem('user'));
    setUser(userStorage)
    setUserName(userStorage?.name);
    loadReviews()

  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterOptionsRef.current &&
        !filterOptionsRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  // const handleFilterChange = (selectedFilter) => {
  //   setFilter(selectedFilter);
  //   setShowFilters(false);
  // };

  // const handleShowMoreToggle = (index) => {
  //   const updatedExpandedReviews = [...expandedReviews];
  //   updatedExpandedReviews[index] = !updatedExpandedReviews[index];
  //   setExpandedReviews(updatedExpandedReviews);
  // };

  // const getShortenedText = (text, maxLength) => {
  //   if (text.length > maxLength) {
  //     return `${text.substring(0, maxLength)}...`;
  //   }
  //   return text;
  // };

  return (
    <>
      <br />
      <div className="review-container">
        <div className="add-review1">
          <button onClick={() => { setVisible(true) }}>
            <img src="../feedback.png"></img>
            Write a Review
          </button>
        </div>
        <br />


        {/* ..................... */}

        <Model isOpen={visible} className='popup' onRequestClose={() => { setVisible(false) }}
          style={{
            overlay: {
              backgroundColor: " #21193534"
            }
          }}
        >
          <h1 className='popup-heding'> Write a Review</h1>
          <br />

          <span >
            <input type="number" className="input-table input-table-width"
              value={rating}
              onChange={handleRatingChange}
            />⭐
          </span>
          <br /> <br />
          <input className='input-table'
            type='text'
          
            placeholder="Write your review here..."
            value={description}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          />

          <br /><br /><br />
          <p className='text-right'>
            <button className='ms-6 text-red-500' onClick={() => { setVisible(false) }}>Cancel</button>
            <button className='ms-6 btn-create' onClick={submitReview}>Add</button>
          </p>
        </Model>

        {/* .............................. */}

        <div className="filter-container">
          <div className="filter-options" ref={filterOptionsRef}>
            {/* {showFilters && (
              <div className="filter-options-list">
                <button onClick={() => handleFilterChange("newest")}>
                  Newest
                </button>
                <button onClick={() => handleFilterChange("oldest")}>
                  Oldest
                </button>
              </div>
            )} */}
            {/* <div
              className="filter-button "
              onClick={() => setShowFilters(!showFilters)}
            >
              Sort By
            </div> */}
          </div>
        </div>
        <div className=" review-scroll-container">
          <div className="div-container">
            {
              filteredReviews().map((review, index) => {
                const { userName, description, rating } = review
                return (
                  <>

                    <SundarjagReview

                      userName={userName}
                      description={description}
                      rating={rating}
                      key={index} />
                  </>
                )

              })
            }
          </div>

        </div>
      </div>
    </>
  );
};

export default ReviewComponent;


