import React, { useState } from "react";
import "./VideoGallery.css";

const VideoGallery = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const openModel = (video) => {
    setSelectedVideo(video);
  };

  const closeModel = () => {
    setSelectedVideo(null);
  };

  const videoData = [
    {
      id: 1,
      name: "Manali",
      content: "This footage is from our last vacation",
      poster: "../Kedarnath/img_10.jpeg",
      videoUrl: "../Kedarnath/video_1.mp4",
    },
    {
      id: 2,
      name: "Kedarnath",
      content: "This footage is from our last vacation",
      poster: "../Kedarnath/posterImg2.png",
      videoUrl: "../Kedarnath/video_2.mp4",
    },

    {
      id: 4,
      name: "Mussoorie",
      content: "This footage is from our last vacation",
      poster: "../Manali/img2.jpg",
      videoUrl: "../Kedarnath/video_4.mp4",
    },
    {
      id: 5,
      name: "Kedarnath",
      content: "This footage is from our last vacation",
      poster: "../Rishikesh/img_15.jpeg",
      videoUrl: "../Kedarnath/video_5.mp4",
    },
    {
      id: 3,
      name: "Nanital",
      content: "This footage is from our last vacation",
      poster: "../Kedarnath/img_1.jpeg",
      videoUrl: "../Kedarnath/video_3.mp4",
    },
  ];

  return (
    <>
      <h1 className="videoh1">Video Gallery</h1>
      <div className="videoGallery grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        {videoData.map((item, index) => (
          <div className="vid pr-4" key={index}>
            <div className="video-container">
              <img src={item.poster} alt={`Video ${item.id}`} />
              <button className="play-button" onClick={() => openModel(item)}>
                {" "}
                <i class="pb-text fa-solid fa-play"></i>
              </button>
              <h3 className="container-h3">{item.name}</h3>
              <p className="container-p">{item.content}</p>
            </div>
          </div>
        ))}

        {selectedVideo && (
          <div className="model">
            <button className="model-close-btn" onClick={() => closeModel()}>
              X
            </button>
            <div className="video-container">
              <video
                className="container-wala-video"
                autoPlay
                controls={false}
                loop
                muted
                onClick={() => closeModel()}
              >
                <source src={selectedVideo.videoUrl} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoGallery;
