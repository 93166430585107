import React from 'react';
import './AboutBackground.css'

const AboutBackground = () => {
    return (
        <div className="about-background">
            <div className="background-image"></div>
            <div className="overlay"></div>
            <div className="content-about">
                <h1 className='bold-h1'>About Us</h1>
                <div className="quote-container">
                    <p className="quote">"At SUNDERJAG, we believe that every journey is a story waiting to be written, filled with moments that create lasting memories.."</p>
                    <p className="quote">"Exploring the extraordinary, crafting memories, and redefining wanderlust—one destination at a time."</p>
                </div>  
            </div>
        </div>
    );
};

export default AboutBackground;

