import React from "react";
import "./HomeImgGallery.css"; // Import the corresponding CSS file

const HomeImgGallery = () => {
  const images = [

    { url: 'https://images.pexels.com/photos/2070485/pexels-photo-2070485.jpeg?auto=compress&cs=tinysrgb&w=600', width: 300, height: 400 },
    { url: 'https://images.pexels.com/photos/210012/pexels-photo-210012.jpeg?auto=compress&cs=tinysrgb&w=600', width: 400, height: 250 },
    { url: 'https://images.pexels.com/photos/3225528/pexels-photo-3225528.jpeg?auto=compress&cs=tinysrgb&w=600', width: 350, height: 400 },
    { url: 'https://images.pexels.com/photos/450441/pexels-photo-450441.jpeg?auto=compress&cs=tinysrgb&w=600', width: 250, height: 250, },
   
    { url: ' https://images.pexels.com/photos/2108845/pexels-photo-2108845.jpeg?auto=compress&cs=tinysrgb&w=600', width: 400, height: 400 , marginTop: -150 },
    { url: 'https://images.pexels.com/photos/2174656/pexels-photo-2174656.jpeg?auto=compress&cs=tinysrgb&w=600', width: 300, height: 250 },

  ];

  return (
    <div className="home-img-gallery">
      <div className="gallery-header">
        <h1>Discover the Beauty</h1>
        <p>Quotes that inspire and images that captivate</p>
      </div>

      <div className="gallery-images">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery-item"
            style={{
              width: image.width,
              height: image.height,
              marginTop: image.marginTop,
            }}
          >
            <img src={image.url} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeImgGallery;
