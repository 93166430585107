import React from "react";
import Gallery from "./Gallery";
import Navbar from "../../components/Navbar/Navbar";
import VideoGallery from "./VideoGallery";

const ImageGallary = () => {
  return (
    <>
      <Navbar />
      <Gallery />
      <VideoGallery />
    </>
  );
};

export default ImageGallary;
