import React from "react";
import "./HomePoster.css";

const HomePoster = () => {
  return (
    <div className="poster-container">
      {/* <div className="content"> */}
         <video  loop muted autoPlay className="content" >
          <source src="../prj1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
     </div>

    //</div>   
  );
};

export default HomePoster;
