/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './TravelingAddPoster.css';  
// import image from "../assets/tl1.avif"// Adjust the path based on your project structure

const TravelingAddPoster = () => {
  return (
    <div className="travel-ad-poster-container">
      <div className="travel-ad-poster-content">
        <h1>Explore the World</h1>
        <p className="slogan">Your Journey Awaits!</p>
        <img
        //   src={image}
        src="https://promos.makemytrip.com/images/summer-holidays-tb-dt-051223.webp"
          alt="Travel Ad Image"
          className="travel-ad-image"
        />
      </div>
    </div>
  );
};

export default TravelingAddPoster;
