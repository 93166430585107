import React from "react";
import "./AboutContent.css";

const AboutContent = ({sideImg}) => {
  return ( 
    <div className="content-container">
      <div className="left-content">
        <h2 className="blue-heading">Behind the Success</h2>
        <p className="left-para">
          Welcome to <b>SUNDERJAG</b> where travel dreams come true! We're
          passionate about creating amazing experiences for every adventurer.
          Our dedicated team of travel experts is here to make your journey
          special by crafting personalized itineraries that cater to your
          interests. Whether you're discovering new cultures or exploring
          beautiful landscapes, we're committed to making every trip memorable.
          With a global network of partners, we bring you exclusive access to
          hidden gems and local treasures. Our goal is to provide seamless and
          enriching journeys that go beyond your expectations. Join us on a
          unique adventure with <b>SUNDERJAG</b> and let's explore the world
          together!
        </p>
      </div>
      <div className="right-content">
        {/* <h3 className="cofounder-heading">Co-Founder</h3>
        <p className="color-head">John Doe</p> */}
        <img
          className="img-cofounder"
          src={sideImg}
          //src="https://images.pexels.com/photos/6953985/pexels-photo-6953985.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Co-Founder"
        />
      </div>
    </div>
  );
};

export default AboutContent;
