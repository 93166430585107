import React, { useState } from "react";
import "./Gallery.css";
import { useParams } from "react-router-dom";
import file from "./config.json";
const Gallery = () => {
  const { id } = useParams();
  const iterator = file[id - 1].imgPath;
  const iteratorName = file[id - 1].name;
  const [selectedImage, setSelectedImage] = useState(null);
  const openModel = (index) => {
    console.log(index + 1);
    setSelectedImage(iterator[index + 1]);
  };

  const closeModel = () => {
    setSelectedImage(null);
  };
  return (
    <>
      <h1 className="imageh1">{iteratorName} Image Gallery</h1>
      <div className="imageGallery grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        {iterator.map((item, index) => {
          console.log(item);
          return (
            <div
              className="pics pr-4"
              key={index}
              onClick={() => openModel(index)}
            >
              <div className="img-container ">
                <img id={index + 1} src={item} alt={index + 1}></img>
              </div>
            </div>
          );
        })}
        {selectedImage && (
          <div className="model">
            <button className="model-close-btn" onClick={() => closeModel()}>
              X
            </button>
            <div className="img-container">
              <img src={selectedImage} alt={selectedImage.name}></img>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
