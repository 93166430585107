import "./Review.css";
import ReviewComponent from "./ReviewComponent";
const Review = () => {
  return (
    <>
      <ReviewComponent />
    </>
  );
};

export default Review;
 