/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./ImageCards.css";

const ImageCards = () => {
  const images = [
    {
      id: 1,
      name: "Kedarnath",
      source: "../Kedarnath/kedarnathLogo.jpg",
    },
    {
      id: 2,
      name: "Rishikesh",
      source: "../Kedarnath/rishikesh.jpg",
    },
    {
      id: 3,
      name: "Mussoorie",
      source: "../Kedarnath/mussoori.jpg",
    },
    {
      id: 4,
      name: "Manali",
      source: "../manali.jpg",
    },
    {
      id: 5,
      name: "Nainital",
      source: "../Nanital/nainital.jpg",
    },
    {
      id: 6,
      name: "Tour",
      source: "../Nanital/tour.jpg",
    },
  ];

  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const startScrolling = () => {
      console.log("STart");
      scrollIntervalRef.current = setInterval(() => {
        container.scrollLeft += container.clientWidth;

        if (
          container.scrollLeft >=
          container.scrollWidth - container.clientWidth
        ) {
          // Reverse the scrolling direction when reaching the end
          container.scrollLeft = 0;
        }
      }, 3000); // Adjust the interval as needed
    };

    // startScrolling();
    if (window.innerWidth > 991) {
      startScrolling();
    }
    return () => {
      clearInterval(scrollIntervalRef.current);
    };
  }, []);
  return (
    <>
      <div className="imageCard-main" ref={containerRef}>
        {images.map((image) => (
          <Link className="link" to={`/ImageGallary/${image.id}`}>
            <div key={image.id} className="containing-image1-div">
              <div className="image1-div-img">
                <img
                  src={image?.source}
                  alt={`image-${image.id}`}
                  id={image.id}
                />
              </div>
              <h2>{image.name}</h2>
            </div>
          </Link>
        ))}
      </div>
      <br />
    </>
  );
};

export default ImageCards;
