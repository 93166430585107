import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import menu from "./menu.png"
import logo from "./sj-logo.png"



function Navbar() {
  const [userdata, setUserdata] = useState({});
  const[menuBtn , setMenuBtn] = useState("disply") 

  useEffect(() => {
    const userFromlocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
    setUserdata(userFromlocalStorage);
  }, [])

  return (
    <div className='nav-container'>
      <div className='logo'>
      <Link to='/' > 
        <img src={logo} alt='SundarJag' className='logo-img' />
        
      </Link>
      </div>
      
      <img src={menu} alt="menu" className='menu-btn'
      onClick={()=>{
        setMenuBtn(menuBtn === "disply" ? "-" : "disply") 
      }}
      />
      <div className={`${menuBtn} links`}>
        <Link to="/"  className="nav-btn" > Home</Link>
        <Link to="/about"  className="nav-btn" > About</Link>
        <Link to="/service" className="nav-btn" > Service</Link>
        <Link to="/plan" className="nav-btn" > Our Plan</Link>
        <Link to="/contact" className="nav-btn" > Contact</Link>
        
        <div className={`user ${menuBtn}`}> 
        {/* Hay!😊{userdata.name} */}

        {
          userdata?.name? (<button className='btn-logout'
          onClick={()=>{
            localStorage.removeItem("user");
            window.location.href = "/login"
          }}
          >Logout</button>) 
          :
          (<button className='btn-logout'
          onClick={()=>{
            window.location.href = "/login"
          }}
          >Login</button>) 
        }
      </div>
      </div>


    </div>
  )
}

export default Navbar