import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./views/Home/Home";
import Contact from "./views/Contact/Contact";
import Service from "./views/Service/Services";
import OurPlan from "./views/OurPlan/OurPlan";
import Login from "./views/Login/Login";
import Signup from "./views/Signup/Signup";
import PackageInfo from "./views/PackageInfo/PackageInfo";
import ImageGallary from "./views/ImageGallary/ImageGallary";
import About from "./views/About/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/service",
    element: <Service />, //Question why we are rendering this
  },
  {
    path: "/plan",
    element: <OurPlan />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/package/:id",
    element: <PackageInfo />,
  },
  {
    path: "/ImageGallary/:id",
    element: <ImageGallary />,
  },
  {
    path : "/about",
    element : <About /> 
  }
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
