import React, { useEffect, useState } from "react";
import axios from "axios";
import "./OurPlanPackageCard.css";
import { Link } from "react-router-dom";
const OurPlanPackageCards = () => {
  const [packages, setPackages] = useState([]);

  const loadPackages = async () => {
    let PackegesData;
    try {
      PackegesData = await axios.get("/api/packages");
    } catch (err) {
      console.log(err);
    }
    setPackages(PackegesData?.data);
  };
  useEffect(() => {
    loadPackages();
    console.log(packages);
  }, []);
  return (
    <>
      <div>
        {packages.map((item, index) => {
          return (
            <div className="main-card-image-price">
              <div className="inmain-image">
                <img src={item.templateimage} alt="" />
              </div>

              <div className="inmain-content">
                <h2 className="heading">{item.name}</h2>
                <div className="mini-con">
                  <img src="./accomadation.png" alt="" />
                  <img src="./food.png" alt="" />
                  <img src="./car.png" alt="" />
                  <img src="./train.png" alt="" />
                  <img src="./airplane.png" alt="" />
                  <img src="./no-tax.png" alt="" />
                </div>
                <p className="description">{item.brief}</p>
              </div>
              <div className="inmain-price">
                <div className="inmain-price-details">
                  <div className="duration-left">
                    <h4> Tour Duration</h4>
                    <h3> {item.duration}</h3>
                  </div>
                  <div className="duration-right">
                    <h4>Base Price</h4>
                    <div className="paise-duration">
                      <h3>
                        <span className="text-red-500">₹</span>
                        {item.rupee}
                      </h3>
                    </div>
                  </div>
                </div>
                <Link to={`/package/${item.id}`}>
                  <button>Full Details</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OurPlanPackageCards;
