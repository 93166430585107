//
//****************************************************************************************

import AboutBackground from "../../components/AboutComponents/AboutBackground";
// import WhyUs from "../../components/WhyUs/WhyUs";
import Navbar from "../../components/Navbar/Navbar";
import AboutContent from "../../components/AboutComponents/AboutContent";
import Footer from "../../components/Footer/Footer";
import teamImg from "./team.png"

const Home = () => {
  return (
    <div>
      <Navbar />
      <AboutBackground />
      <AboutContent sideImg={teamImg} />
      <Footer />
    </div>
  );
};
export default Home;
