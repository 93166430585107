import React from "react";
import HomePackageCard from "./HomePackageCard";
const PackageCard = () => {
  return (
    <>
      <HomePackageCard />
    </>
  );
};

export default PackageCard;
