// Footer.js

import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

import logo from "../Navbar/sj-logo.png";
import logo2 from "./sundarjag.png"

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section">
        <div className="footer-logo">
          <Link to="/">
            <img src={logo} alt="SundarJag" className="logo-img1" />
            <img src={logo2} alt="SundarJag" className="logo-img2" />
          </Link>
        </div>
        <div className="footer-content">
          <div className="footer-main-links">
            <h2>Main Links</h2>
            <ul>
              <li> 
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                {/* <Link to="/service">Services</Link> */}
                <a href="/service">Services</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>{" "}

          </div>
          <div className="torrist-service">
            <h2>Tourist Services Links</h2>
            <ul>
              <li>
                {/* <Link to="/service">Services</Link> */}
                <a href="/service">Services</a>
              </li>
              <li>
                <a href="/plan">Our Plan</a>
              </li>
              <li>
                <a href="#">Package</a>
              </li>
            </ul>
          </div>
          <div className="contact-info">
            <h2>Contact Info</h2>
            <p className="contact-info-email">
              Email: sunderjagholidays@gmail.com
            </p>
            <p>Phone: +917972919474</p>
            <p className="address">Address: Nagpur, Maharastra (440027)</p>
          </div>
          <div className="follow-us">
            <h2>Follow Us</h2>
            <div className="social-icons">
              <a href="https://www.facebook.com/people/Sunder-Jag-Holidays/61556475502723/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/sunderjagholidays"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright-footer">
          <i class="fa-regular fa-copyright"> </i>{" "}
          <p className="copy-heading"> 2024 by sunderjagholidays.com</p>
        </div>
        {/* <p className="copy-content">
          All rights are reserved. No reproduction or distribution of this
          publication, except for brief quotations in critical reviews and
          certain noncommercial uses, without prior written permission from the
          publisher.
        </p> */}
      </div>
    </footer>
  );
};

export default Footer;
