import React from "react";
import "./ContactHeader.css";
const ContactHeader = () => {
  const scrollToForm = () => {
    // Get the form element by its ID
    const form = document.getElementById("contact-form");

    // Scroll to the form using scrollIntoView
    form.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div className="contact-new-container">
        <div className="contact-data">
          <div className="leftcontent">
            <h2>Customer Support</h2>
            <p>
              Our dedicated Customer Support Team is ready to equip you with
              comprehensive information about our tours and procedures. Count on
              us to assist you at every stage, ensuring your travel experience
              with Sunderjag Tours is smooth and unforgettable. Feel free to
              reach out to us now for prompt answers to all your travel-related
              queries!
            </p>
            <div onClick={scrollToForm} className="contact-button">
              <button>Ask a Question</button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-head">
        <div className="contact-heading">
        <h1>Sundarjag</h1>
      </div>

        <div className="contact-content">
          <div className="rightcontent">
            <img src="../contactus.png" alt="supportImage"></img>
          </div>
        </div> 
      </div>*/}
    </>
  );
};

export default ContactHeader;
