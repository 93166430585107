import "./OurPlan";
import Navbar from "../../components/Navbar/Navbar";
import OurPlanPackageCards from "../../components/OurPlanPackageCards/OurPlanPackageCards";
import Footer from "../../components/Footer/Footer";

const OurPlan = () => {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div>
        <OurPlanPackageCards />
      </div>
      <Footer />
    </>
  );
};

export default OurPlan;
