import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
// import "./PackageInfo.css";
import "./Accordion.css"

const Accordion = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  if (!sections || sections.length === 0) {
    return <p>No sections available</p>;
  }
  
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {sections.map((section, index) => {
        // Check if section has the required properties
        if (!section || typeof section.title !== 'string' || typeof section.details !== 'string') {
          console.error(`Invalid section at index ${index}:`, section);
          return null; // Skip rendering this invalid section
        }

        return (
          <div key={index} className="accordion-section">
            <div
              className={`accordion-title p-4 rounded-lg my-4 border-2 border-solid border-blue-500 relative text-blue-500 font-bold cursor-pointer`}
              onClick={() => handleClick(index)}
            >
              {section.title}
              <div className="absolute right-4 top-1/2 transform -translate-y-1/2" id=''>
                {activeIndex === index ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </div>
            </div>
            <div
              className={`accordion-content text-gray-700 font-semibold ${activeIndex === index ? 'block' : 'hidden'}`}
            >
              {section.details}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;



