import "./Contact.css";
import Navbar from "../../components/Navbar/Navbar";
import ContactUs from "./ContactUs";
import ContactHeader from "../../components/ContactHeader/ContactHeader";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer/Footer";

const Contact = () => {
  // react-scroll-trigger
  return (
    <>
      <div>
        <Navbar />
      </div>
      {/* <h1>Contact</h1> */}
      <ContactHeader />
      <ContactUs />
      <Footer />
      {/* <ContactForm /> */}
    </>
  );
};

export default Contact;
