
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./Carousal.css"

const Carousals = ({ media }) => {

  if (!media || media.length === 0) {
    // Return some default content or a message when media is undefined or empty
    return <p>No media available</p>;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>
      {media.map((item, index) => (
        <div key={index}>
          {item.type === 'image' ? (
            <img src={item.source} alt={`${index + 1}`} className='caurousal-img'/>
          ) : (
            <video width="100%" height="auto" autoPlay muted loop caurousal-img>
              <source src={item.source} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ))}
    </Slider>
  );
};

export default Carousals;



