import React from "react";
import "./ContactOnHome.css";
import { Link } from "react-router-dom";
const ContactOnHome = () => {
  return (
    <>
      <div className="contact-contentOnHome">
        <div className="leftcontentOnHome">
          <h2>Customer Support</h2>
          <p>
            Our dedicated Customer Support Team is ready to equip you with
            comprehensive information about our tours and procedures. Count on
            us to assist you at every stage, ensuring your travel experience
            with Sunderjag Tours is smooth and unforgettable. Feel free to reach
            out to us now for prompt answers to all your travel-related queries!
          </p>
          <div className="contact-buttonOnHome">
            <a href="/Contact">
              <button>Contact Us</button>
            </a>
          </div>
        </div>
        <div className="rightcontentOnHome">
          <h2>Customer Support</h2>
          <img src="../contactus.png" alt="supportImage"></img>
        </div>
      </div>
    </>
  );
};

export default ContactOnHome;
